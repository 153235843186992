@import "./_constants.scss";

.App {
  text-align: center;
}

.App-logo {
  // height: 40vmin;
  // pointer-events: none;
  height: 100%;
}

.App-header {
  // background: $secondary;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  // z-index: 2;
  // position: fixed;
  position: absolute;
  // background: $darkPrimaryWash;
  color: $white;
  left: 0;
  right: 0;
  top: 0;
}

.AppHeaderDark {
  background: $darkPrimaryWash;
  position: relative;
}

.App-header-white {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: $white;
  color: $darkPrimaryWash;
}

.App-link {
  color: #61dafb;
}

.app_headerContainer {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  max-width: $maxWidth;
  width: 80%;
  margin: auto;
  align-items: center;
  height: 60px;

  h2 {
    margin: 0;
  }
}
