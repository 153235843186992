@import "./_constants.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");

.shared_container {
  width: 80%;
  max-width: $maxWidth;
  margin: auto;
  text-align: left;
  padding: 60px 0 80px 0;
}

.ContainerLight {
  background: $wash;
}

.ContainerDark {
  background: $darkPrimaryWash;
  color: $white;
}

.ContainerPale {
  background: $paleWash;
}

.ContainerDarkPale {
  background: $darkPaleWash;
  color: $white;
}

.ContainerWhite {
  background: $white;
}

.ContainerBlue {
  background: $blueWash;
}

.ContainerPrimary {
  background: $primary;
  color: $white;
}

.marginBottom20 {
  margin-bottom: 24px;
}

.marginRight8 {
  margin-right: 8px;
}

.marginBottom12 {
  margin-bottom: 20px;
}

.marginLeftAuto {
  margin-left: 50%;

  @media (max-width: $breakpoint-tablet) {
    margin-left: 0;
  }
}

.mobileOnly {
  display: none;

  @media (max-width: $breakpoint-phone) {
    display: block;
  }
}

a.simple {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.undecorated {
  text-decoration: none;
  color: inherit;
}

.ContainerCenter {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

body,
html {
  font-family: "DM Sans", sans-serif;
  color: $primaryText;
  width: 100%;
  overflow-x: hidden;
}

p {
  font-size: 20px;
  line-height: 1.5;
  max-width: $textMaxWidth;
  margin: 0;
  // opacity: 0.9;
}

h1 {
  font-size: 56px;
  max-width: $textMaxWidth;
  margin: 0;
  font-weight: 600 !important;
  line-height: 1.2 !important;

  @media (max-width: $breakpoint-tablet) {
    font-size: 44px;
  }
}

h2 {
  font-size: 40px;
  max-width: $textMaxWidth;
  margin: 0;
  margin-bottom: 16px;
  font-weight: 600 !important;
  line-height: 1.15 !important;

  @media (max-width: $breakpoint-tablet) {
    font-size: 36px;
  }
}

h3 {
  font-size: 20px;
  max-width: $textMaxWidth;
  margin: 0;
  margin-bottom: 8px;
  line-height: 1.5 !important;
}

h4 {
  font-size: 30px;
  font-weight: 500 !important;
  max-width: 650px;
  margin: 0;

  @media (max-width: $breakpoint-tablet) {
    font-size: 28px;
  }
}

* {
  transition: 0.2s ease-in-out all;
}

input {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 1.4;
  outline: none;
}

button {
  -webkit-appearance: none;
  border: 0;
  background: none;
  padding: 8px 32px;
  border-radius: 8px;
  border: 2px solid $primary;
  color: $primary;
  font-size: 14px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  // text-transform: uppercase;
  letter-spacing: 0.6px;

  @media (max-width: $breakpoint-phone) {
    width: 100%;
  }

  &:hover {
    background: $primary;
    color: $white;
  }
}

button.cool,
a.cool,
input[type="submit"] {
  -webkit-appearance: none;
  border: 0;
  background: none;
  background: $primary;
  padding: 10px 40px;
  border-radius: 8px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  // text-transform: uppercase;
  letter-spacing: 0.6px;

  @media (max-width: $breakpoint-phone) {
    width: 100%;
  }

  &.outline {
    border: 2px $primary solid;
    background: transparent;
    color: $primary;

    &:hover {
      background: $primary;
      color: $white;
    }
  }

  &.dark {
    background: $primaryText;

    &.blank {
      background: none;
      border: none !important;
      color: $primaryText;
    }
  }

  &.blank {
    background: none;
    border: none !important;
    color: $primary;

    &.white {
      color: $white;
    }
  }
}

button.long,
a.long {
  width: 100%;
}

input[type="submit"].long {
  width: 80%;
  cursor: pointer;

  @media (max-width: $breakpoint-phone) {
    width: 100%;
  }
}

.home {
  .splash {
    padding: 120px 0 60px 0;
    background: $darkPrimaryWash;
    background: url("../images/splash.png") $darkPrimaryWash;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: calc(50% + 280px) 120%;
    color: $darkPrimaryText;

    @media (max-width: $breakpoint-tablet) {
      background: url("../images/mobile/virtual.svg") $darkPrimaryWash;
      background-size: auto 260px;
      background-repeat: no-repeat;
      background-position: bottom right;
    }

    @media (max-width: $breakpoint-phone) {
      padding: 60px 0 0px 0;
      background-image: none;
    }

    p {
      margin: 8px 0 20px 0;
    }
  }

  .splash.member {
    background: url("../images/team-spirit.png") $darkPrimaryWash;
    background-size: auto 120%;
    background-repeat: no-repeat;
    background-position: center right;

    @media (max-width: $breakpoint-small-computer) {
      background-size: auto 100%;
      background-position: 100% 20%;
    }

    @media (max-width: $breakpoint-phablet) {
      background-image: none;
    }
  }

  .signUp {
    display: flex;
    padding: 40px 0;
    background: $wash;
  }
}

.NavButtons {
  display: flex;
  justify-content: flex-end;
  z-index: 1;

  @media (max-width: $breakpoint-phablet) {
    display: none;
  }

  form {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-right: 16px;
    max-width: min-content;
  }

  .loginFormdiv {
    margin-left: 10px;
    margin-bottom: 0;
  }
}

.howItWorks {
  text-align: center;
  background-image: url("../images/home/coffee.png");
  background-size: auto 60%;
  background-repeat: no-repeat;
  background-position: calc(50% - 380px) 110%;

  @media (max-width: $breakpoint-small-computer) {
    background-position: -70px 110%;
  }

  @media (max-width: $breakpoint-tablet) {
    background-image: none;
  }

  .howItWorks_container {
  }

  .howItWorks_steps {
    background-image: url("../images/home/how.png");
    background-size: auto 300px;
    background-repeat: no-repeat;
    background-position: 105% -5%;
    padding-top: 20px;

    @media (max-width: $breakpoint-tablet) {
      background-image: none;
    }
    h2,
    p {
      text-align: left !important;
    }
    .howItWorks_step {
      width: 50%;
      margin-top: 20px;
      margin-bottom: 60px;
      margin-left: 0%;
      @media (max-width: $breakpoint-tablet) {
        margin-left: 0 !important;
        width: 100%;
      }

      &:nth-child(2) {
        margin-left: 25%;
      }

      &:nth-child(3) {
        margin-left: 50%;
        margin-bottom: 0px;
      }

      .howItWorks_stepImage {
        // text-align: center;

        img {
          width: 80px;
          margin: auto;
        }
      }

      .howItWorks_stepText {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;

        @media (max-width: $breakpoint-phone) {
          display: block;
        }
        h2,
        h3,
        h4 {
          text-align: left;
        }

        .howItWorks_stepTextNum {
          margin-right: 40px;
          // position: absolute;
          // right: 95%;
          // opacity: 0.2;
          bottom: 0;
          // display: none;
          img {
            height: 120px;

            @media (max-width: $breakpoint-phone) {
              height: 100px;
            }
          }
        }
      }
    }
  }
}

.SplashSharedMeals {
  background-image: url("../images/shared.png");
  background-size: auto 70%;
  background-repeat: no-repeat;
  background-position: calc(50% + 400px) center;

  @media (max-width: $breakpoint-small-computer) {
    background-image: url("../images/shared-portait.png");
    background-size: auto 70%;
    background-repeat: no-repeat;
    background-position: 90% center;
  }

  @media (max-width: $breakpoint-tablet) {
    background-image: url("../images/shared-portait.png");
    background-size: auto 70%;
    background-repeat: no-repeat;
    background-position: 150% center;
  }

  @media (max-width: 720px) {
    background-image: none;
  }

  @media (max-width: $breakpoint-phone) {
    padding-bottom: 90px;
  }

  .SplashSharedMealsContent {
    width: 100%;
    max-width: $textMaxWidth;
    // margin-left: auto;
    margin-right: 60px;
  }

  // display: flex;

  // .SplashMissionImage {
  //   width: 40%;
  //   max
  // }
}

.SplashMission {
  background-image: url("../images/mobile/blob-right.svg");
  background-repeat: no-repeat;
  background-position: right calc(100% + 20px);

  img {
    width: 120%;
    margin-top: -40px;
    margin-left: -40px;
  }
}

.ForMe {
  background-image: url("../images/hands.svg");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: calc(50% + 280px) 140px;

  @media (max-width: $breakpoint-tablet) {
    background-size: 40% auto;
    background-position: 110% 120%;
  }

  @media (max-width: $breakpoint-phone) {
    background-image: none;
  }

  .mobileMeal {
    width: 80%;
    margin-top: 0;
    transform: translateY(-52.5%);
    position: absolute;
    left: 10%;
  }

  .ForMe_container {
    @media (max-width: $breakpoint-phone) {
      padding-top: 160px;
    }
  }
}

.Need {
  background-image: url("../images/need.png");
  background-size: auto 130%;
  background-repeat: no-repeat;
  background-position: calc(50% - 280px);

  @media (max-width: $breakpoint-tablet) {
    background-image: none;
  }

  .NeedContent {
    margin-left: 50%;

    @media (max-width: $breakpoint-tablet) {
      margin-left: 0;
    }
  }
}

.Testimonials {
  background-image: url("../images/home/hand.png");
  background-size: auto 120px;
  background-repeat: no-repeat;
  background-position: calc(50% - 550px) 110%;
  position: relative;

  .Testimonials_container {
    position: relative;
  }

  img {
    position: absolute;
    width: 450px;
    top: -20px;
    right: -100px;

    @media (max-width: $breakpoint-tablet) {
      width: 160px;
      top: -20px;
      right: -5%;
    }

    @media (max-width: $breakpoint-phone) {
      width: 80px;
      top: -10px;
      right: -10%;
    }
  }
}

.loggedInPage {
  .loggedInPage_dashboard {
    background: $darkPrimaryWash;
    padding: 80px 0;
    padding-top: 120px;
    color: $darkPrimaryText;
  }
}

.howTo_container {
  padding: 80px 0;

  .howTo_content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    align-items: center;
  }

  .howTo_video {
    width: 360px;
    height: 240px;
    // background: $wash;
    margin-right: 20px;

    @media (max-width: $breakpoint-phone) {
      width: 100%;
      height: 200px;
    }

    iframe {
      width: 360px;
      height: 240px;

      @media (max-width: $breakpoint-phone) {
        width: 100%;
        height: 200px;
      }
    }
  }

  .howToOptions {
    display: flex;

    .howToOption {
      width: 200px;
      margin-right: 20px;
      opacity: 0.5;

      &:hover,
      &.active {
        cursor: pointer;
        opacity: 1;
        // transform: scale(1.05);
      }
      img {
        height: 100px;
      }
    }
  }
}

.ConvoStarterContainer {
  background-image: url("../images/chat.svg");
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: calc(50% + 400px) bottom;

  @media (max-width: $breakpoint-small-computer) {
    background-size: auto 70%;
    background-repeat: no-repeat;
    background-position: 90% bottom;
  }

  @media (max-width: $breakpoint-tablet) {
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: 90% bottom;
  }

  @media (max-width: 720px) {
    background-image: none;
  }

  @media (max-width: $breakpoint-phone) {
    padding-bottom: 90px;
  }

  .ConvoStarterContent {
    width: 100%;
    max-width: $textMaxWidth;
    // margin-left: auto;
    margin-right: 60px;
  }

  ul {
    font-size: 20px;
  }

  li {
    padding: 5px 0;
  }
}

.text {
  max-width: $textMaxWidth;
}

.awaitingMatch_tips {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  .awaitingMatch_tip {
    width: 240px;
    margin-right: 80px;

    .awaitingMatch_image {
      width: 240px;
      height: 240px;
      background: #ccc;
      margin-bottom: 8px;
    }
  }
}

.matchInfoContainer {
  padding: 40px;
  background: $white;
  color: $primaryText;
  max-width: 400px;
  border-radius: 8px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 40px;
    box-sizing: border-box;
    padding: 20px;
  }

  .matchInfoFlex {
    display: flex;

    @media (max-width: $breakpoint-tablet) {
      display: block;
    }

    .matchInfo {
      width: 160px;
      margin-right: 16px;

      &.languages {
        p {
          text-transform: capitalize;
        }
      }
    }
  }

  .matchInfo {
    margin-top: 16px;
  }
}
.MatchFoundFlex {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;

  @media (max-width: $breakpoint-tablet) {
    display: block;
  }
}

.matchRatingContainer {
  padding: 40px;
  background: $white;
  color: $primaryText;
  border-radius: 8px;
  max-width: 400px;
  margin-right: 40px;
  width: 100%;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 40px;
    box-sizing: border-box;
    padding: 20px;
  }
}

.MembersOuterContainer {
  text-align: center;
  background-image: url(/static/media/blob-right.6af659b4.svg);
  background-repeat: no-repeat;
  background-position: right calc(100% + 20px);

  & > div > img {
    width: 120%;
    margin-top: -40px;
    margin-left: -40px;
  }

  .members_inner_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .member_each {
      margin: 10px;
      width: 20%;
      align-self: baseline;

      @media (max-width: $breakpoint-tablet) {
        width: 30%;
      }

      @media (max-width: $breakpoint-phablet) {
        width: 45%;
      }

      @media (max-width: $breakpoint-phone) {
        width: 80%;
      }

      &:hover {
        .member_text_outer .member_text_inner {
          top: -30px;
        }
      }

      &:hover {
        .member_img_container svg path {
          d: path(
            "M205.1,-163.6C253.9,-102.4,273.2,-17.8,255.8,58.4C238.4,134.6,184.3,202.4,113.8,236.4C43.4,270.3,-43.4,270.3,-113.8,236.4C-184.3,202.4,-238.4,134.6,-255.8,58.4C-273.2,-17.8,-253.9,-102.4,-205.1,-163.6C-156.4,-224.7,-78.2,-262.3,0,-262.3C78.2,-262.3,156.4,-224.7,205.1,-163.6Z"
          );
        }
      }

      .member_text_outer {
        height: 30px;
        overflow: hidden;
        position: relative;

        .member_text_inner {
          position: absolute;
          top: 0px;
          left: 0;
          right: 0;

          h3 {
            margin: 0;
            line-height: 30px;
          }

          p {
            margin: 0;
            line-height: 30px;
            font-size: 15px;
            text-transform: initial;
            color: $primary;
          }
        }
      }

      .member_img_container {
        display: flex;
        justify-content: center;
        position: relative;

        svg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          z-index: 0;
        }

        svg path {
          fill: $light;
        }

        img {
          max-width: 65%;
          padding: 20px;
          margin: auto;
          z-index: 1;
          border-radius: 100%;
        }
      }
    }
  }
}

.dialogueContainer {
  padding: 20px 0;
  max-width: 600px;
  text-align: center;
  width: 50vw;

  h4 {
    margin: 0 auto;
    color: $darkPrimaryWash;
  }

  h5 {
    margin: 0;
    font-weight: 300;
    color: $primary;
  }

  p {
    font-size: 17px;
    margin: 15px auto;
    padding: 0 20px;
    color: #555;

    @media (max-width: $breakpoint-phablet) {
      padding: 0 30px;
    }
  }

  a {
    color: $darkPrimaryWash;
    text-decoration: none;
    display: inline-block;
    padding: 3px;
    position: relative;

    &:after {
      content: "";
      width: 0px;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: $darkPrimaryWash;
      transition: 0.3s ease;
    }

    &:hover:after {
      width: 100%;
    }
  }
}

.Footer {
  .shared_container {
    padding: 40px;
  }

  p {
    font-size: 12px;
  }

  img {
    width: 100px;
    margin-bottom: 10px;
  }

  a {
    color: inherit;
  }
}
