$maxWidth: 1024px;
$textMaxWidth: 460px;
$secondary: #eff3e6;
$primary: #e16751;
$white: white;
$wash: #f9f7f6;
$darkwash: #e9e7e5;
$blueWash: #a3e3d5;
$paleWash: #fbf7f6;
$darkPaleWash: #f3c49a;
$primaryWash: #cdeafd;
$primaryText: #1a3c46;
$darkPrimaryWash: #1a3c46;
$darkPrimaryText: #fff;
$light: #facfc5;
$breakpoint-phone: 486px;
$breakpoint-tablet: 986px;
$breakpoint-phablet: 800px;
$breakpoint-small-computer: 1200px;
