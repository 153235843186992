@import "./_constants.scss";

.timePicker {
  display: flex;
  flex-direction: column;
  max-width: 420px;
  border: 1px solid $darkPrimaryWash;
}

.timePicker__days,
.timePicker__times {
  display: flex;
}

.timePicker__days {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.timePicker__times {
  flex-direction: row;
  flex-wrap: wrap;
}

.timePicker__day {
  text-transform: capitalize;
  text-align: center;
  padding: 8px 16px;
  background: #eee;
  box-sizing: border-box;
  flex: 1 0 auto;
  border: 0.5px solid $darkPrimaryWash;
  cursor: pointer;
}

.timePicker__day.active,
.timePicker__day:hover {
  color: white;
  background: $darkPrimaryWash;
}

.timePicker__time.active,
.timePicker__time:hover {
  background: $blueWash;
}

.timePicker__time {
  width: 33%;
  flex: 1 1 auto;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  border: 0.5px solid $darkPrimaryWash;
  transition: 0.2s ease all;
}

.TimePickerSelectedTimes {
  max-width: 400px;
  width: 100%;
}

.TimePickerSelectedTimesDay {
  margin: 8px 0;
  text-transform: capitalize;
}

.TimePickerSelectedTimesChip {
  margin-left: 4px;
  margin-bottom: 4px;
}
