@import "./_constants.scss";

form {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 400px;
}

form > div {
  margin-bottom: 16px;
}

.form__div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
form h3 {
  text-align: left;
}

p.formHelper {
  font-size: 16px;
}

.FormFlex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: $breakpoint-tablet) {
    display: block;
  }
}
.form__div__new {
  margin-bottom: 28px;

  .MuiTextField-root,
  .MuiFormControl-root {
    @media (max-width: $breakpoint-phone) {
      width: 100%;
    }
  }

  .MuiInputBase-input,
  .MuiFormLabel-root {
    font-family: "DM Sans", sans-serif !important;
    color: $darkPrimaryWash !important;
  }
  .MuiInput-underline:after {
    border-color: $darkPrimaryWash;
  }
  .MuiFormLabel-root {
    margin-bottom: 4px;
    margin-left: 8px;
    z-index: 2;
    margin-top: 8px;
  }

  .MuiInputLabel-shrink {
    font-size: 20px;
    margin-left: 0;
    // margin-bottom: 8px;
    margin-top: 0px;
    z-index: 0 !important;
    // margin
  }

  .MuiInput-root,
  .MuiFormHelperText-root {
    width: 400px;
    max-width: 80%;

    @media (max-width: $breakpoint-phone) {
      max-width: 100%;
    }
  }
}

.form__div textarea,
.form__div input,
.form__div select {
  padding: 10px;
  margin-top: 4px;
}

.form__div textarea,
.form__div input {
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}

.form__div input[type="radio"] {
  width: auto;
}

.form__div textarea {
  border-radius: 4px;
}
